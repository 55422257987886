import React from 'react';

const features = {
  free: [
    {
      text: 'Comment & annotate',
      tooltip:
        'Get precise feedback, directly on videos, images, PDFs and more.',
    },
    {
      text: 'Unlimited free shares',
      tooltip:
        'Share your work in comment-able Review Links, or a beautiful Presentation Link format.',
    },
    {
      text: 'Apps & integrations',
      tooltip:
        'Keep your flow going with our award-winning iPhone app, Mac Watch Folders and integrations with Apple Final Cut Pro, Blackmagic DaVinci Resolve 16 Studio, Adobe Premiere Pro, Adobe After Effects and AVID Media Composer. ',
    },
    {
      text: 'Version management',
      tooltip: 'Reorder, remove or compare versions side-by-side.',
    },
    {
      text: '1080p HD playback',
      tooltip: 'Stream and share your media in HD.',
    },
    {
      text: 'Industry-leading security',
      tooltip:
        'Frame.io is TPN accredited SOC 2 Type 2 compliant. Proven security standards audited by third parties.',
    },
  ],
  pro: [
    {
      text: 'Custom-branded presentations',
      tooltip: 'Customize presentation pages with your branding.',
    },
    {
      text: 'Password-protected shares',
      tooltip: 'Secure your share links with custom passwords.',
    },
    {
      text: 'Project archiving',
      tooltip: 'File away inactive projects without using up active storage.',
    },
    {
      text: '4K Ultra HD playback',
      tooltip: 'Stream and share your media in Ultra HD.',
    },
  ],
  team: [
    {
      text: 'Private projects',
      tooltip: 'Keep works-in-progress hidden with invite-only projects.',
    },
    {
      text: 'Team-only comments',
      tooltip:
        'Separate internal conversations from conversations with clients and collaborators.',
    },
    {
      text: 'Share link expiration',
      tooltip: 'Set an expiration date on your share links for added control. ',
    },
    {
      text: 'Custom-branded emails',
      tooltip: 'Customize notification emails with your branding.',
    },
  ],
  enterprise: [
    {
      text: 'Apple TV app',
      tooltip: 'Watch video and stills sent to your secure inbox.',
    },
    {
      text: 'Watermark ID',
      tooltip:
        'Design custom watermarks for your highest security assets that will be burned into each frame when the viewer presses play. Add the viewer’s email address, IP address, date time, geolocation and more.',
    },
    {
      text: 'Digital Rights Management',
      tooltip:
        'Server side encryption prevents unauthorized viewing of your pre-release content.',
    },
    {
      text: 'SAML-based SSO',
      tooltip:
        'Simple, secure login with your existing SAML 2.0-compatible SSO provider, such as Okta, One Login or ADFS.',
    },
    {
      text: 'Two-Factor Authentication',
      tooltip:
        'Add an additional layer of protection to your name and password login credentials.',
    },
    {
      text: 'Reviewer inbox',
      tooltip:
        'Restrict share links to only the emails you specify. Recipients can access everything you share with them in a dedicated Inbox view.',
    },
    {
      text: 'Multi-Team management',
      tooltip:
        'Maintain separate, private Frame.io workspaces for each client, department, production or brand.',
    },
    {
      text: 'Custom branding by Team',
      tooltip:
        'Granular customization of emails and presentations for each client, department, production or brand.',
    },
    {
      text: 'Asset lifecycle management',
      tooltip:
        "Set your preferred limit on an asset age by workspace and we'll clean out old files.",
    },
    {
      text: 'Custom API Workflows',
      tooltip:
        'Create custom integrations to optimize Frame.io for your specific needs.',
    },
    {
      text: 'Priority in-app support',
      tooltip:
        'Enhanced support with 1-hour response time, enterprise-level SLA, emergency phone support, escalated bug reporting and more. ',
    },
    {
      text: 'Account Management team',
      tooltip:
        'Our team assists with your account needs and provides in-depth training across the Frame.io suite.',
    },
  ],
};

export const plans = {
  monthly: [
    {
      id: 'free',
      title: 'Free',
      subtitle: 'Review and approval for freelance projects',
      price: '0',
      pricePer: '',
      priceDetails: 'Maximum of 2 users',
      reviewers: { text: 'Unlimited' },
      projects: { text: '2' },
      activeStorage: { text: '2 GB' },
      archivalStorage: { text: '0 GB' },
      cameraToCloud: { text: 'No' },
      features: features.free,
      label: 'Sign Up',
    },
    {
      id: 'pro',
      title: 'Pro',
      subtitle: 'Enhanced features for organizations',
      price: '15',
      pricePer: (
        <>
          per user
          <br />
          per month
        </>
      ),
      priceDetails: 'Maximum of 10 users',
      url: 'https://accounts.frame.io/promotions/V6PM7DCC',
      label: 'Try For Free',
      projects: { text: 'Unlimited' },
      reviewers: { text: 'Unlimited' },
      activeStorage: {
        text: '250 GB',
        tooltip:
          'Additional 250 GB storage add-ons are available for $15/mo billed monthly or $13/mo billed annually.',
      },
      archivalStorage: {
        text: '500 GB',
        tooltip:
          'Additional 1 TB archival storage add-ons are available for $10/mo billed monthly or $9/mo billed annually.',
      },
      cameraToCloud: {
        text: 'Yes',
      },
      hasEverythingIn: 'Free',
      features: features.pro,
    },
    {
      id: 'team',
      isHighlighted: true,
      title: 'Team',
      subtitle: 'More options to customize workflow',
      price: '25',
      pricePer: (
        <>
          per user
          <br />
          per month
        </>
      ),
      priceDetails: 'Maximum of 15 users',
      url: 'https://accounts.frame.io/promotions/V6TM7DCC ',
      label: 'Try For Free',
      projects: { text: 'Unlimited' },
      reviewers: { text: 'Unlimited' },
      activeStorage: {
        text: '500 GB',
        tooltip:
          'Additional 250 GB storage add-ons are available for $15/mo billed monthly or $13/mo billed annually.',
      },
      archivalStorage: {
        text: '1 TB',
        tooltip:
          'Additional 1 TB archival storage add-ons are available for $10/mo billed monthly or $9/mo billed annually.',
      },
      cameraToCloud: {
        text: 'Yes',
      },
      hasEverythingIn: 'Pro',
      features: features.team,
    },
    {
      id: 'enterprise',
      title: 'Enterprise',
      subtitle: 'Advanced security and workflow at scale',
      isCustom: true,
      hasEverythingIn: 'Team',
      features: features.enterprise,
      price: 'Custom',
      priceDetails: (
        <>
          <br />
        </>
      ),
      url: '#enterprise-demo-1',
      label: 'Speak With Sales',
    },
  ],
  annual: [
    {
      id: 'free',
      title: 'Free',
      subtitle: 'Review and approval for freelance projects',
      price: '0',
      pricePer: '',
      priceDetails: 'Maximum of 2 users',
      reviewers: { text: 'Unlimited' },
      projects: { text: '2' },
      activeStorage: { text: '2 GB' },
      archivalStorage: { text: '0 GB' },
      cameraToCloud: { text: 'No' },
      features: features.free,
      label: 'Sign Up',
    },
    {
      id: 'pro',
      title: 'Pro',
      subtitle: 'Enhanced features for organizations',
      price: '13',
      pricePer: (
        <>
          per user
          <br />
          per month
        </>
      ),
      priceBilling: 'Billed annually',
      priceDetails: 'Maximum of 10 users',
      url: 'https://accounts.frame.io/promotions/V6PY7DCC',
      label: 'Try For Free',
      projects: { text: 'Unlimited' },
      reviewers: { text: 'Unlimited' },
      activeStorage: {
        text: '250 GB',
        tooltip:
          'Additional 250 GB storage add-ons are available for $15/mo billed monthly or $13/mo billed annually.',
      },
      archivalStorage: {
        text: '500 GB',
        tooltip:
          'Additional 750 GB archival storage add-ons are available for $10/mo billed monthly or $9/mo billed annually.',
      },
      cameraToCloud: {
        text: 'Yes',
      },
      hasEverythingIn: 'Free',
      features: features.pro,
    },
    {
      id: 'team',
      isHighlighted: true,
      title: 'Team',
      subtitle: 'More options to customize workflow',
      price: '22',
      pricePer: (
        <>
          per user
          <br />
          per month
        </>
      ),
      priceBilling: 'Billed annually',
      priceDetails: 'Maximum of 15 users',
      url: 'https://accounts.frame.io/promotions/V6TY7DCC',
      label: 'Try For Free',
      projects: { text: 'Unlimited' },
      reviewers: { text: 'Unlimited' },
      activeStorage: {
        text: '500 GB',
        tooltip:
          'Additional 250 GB storage add-ons are available for $15/mo billed monthly or $13/mo billed annually.',
      },
      archivalStorage: {
        text: '1 TB',
        tooltip:
          'Additional 750 GB archival storage add-ons are available for $10/mo billed monthly or $9/mo billed annually.',
      },
      cameraToCloud: {
        text: 'Yes',
      },
      hasEverythingIn: 'Pro',
      features: features.team,
    },
    {
      id: 'enterprise',
      title: 'Enterprise',
      price: 'Custom',
      priceDetails: (
        <>
          <br />
        </>
      ),
      url: '#enterprise-demo-1',
      label: 'Speak With Sales',
      subtitle: 'Advanced security and workflow at scale',
      isCustom: true,
      hasEverythingIn: 'Team',
      features: features.enterprise,
    },
  ],
};
