import styled from 'styled-components';

import { media } from 'src/utils/mixins';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const Label = styled.div`
  ${({ theme }) => theme.text.aktivGrotesk};

  color: ${({ theme }) => theme.color.white};
  font-size: 12px;
  letter-spacing: 1px;
  opacity: 0.5;
  text-transform: uppercase;
  transition: opacity 100ms ${({ theme }) => theme.ease.short};

  ${props =>
    props.isActive &&
    `
    opacity: 1;
  `};
`;

export const ToggleBtn = styled.button`
  border: 2px solid ${({ theme }) => theme.color.purpleLight};
  border-radius: 100px;
  height: 30px;
  margin: 0 10px;
  position: relative;
  width: 60px;
`;

export const ToggleBtnInner = styled.div`
  background-color: ${({ theme }) => theme.color.purpleLight};
  border-radius: 50%;
  height: 28px;
  left: -1px;
  position: absolute;
  top: -1px;
  transition: transform 100ms ${({ theme }) => theme.ease.short};
  width: 28px;

  ${props =>
    props.isAnnual &&
    `
    transform: translateX(calc(100% + 2px));
  `};
`;

export const Discount = styled.div`
  ${({ theme }) => theme.text.avenirBold};

  color: ${({ theme }) => theme.color.green};
  font-size: 10px;
  letter-spacing: -0.3px;
  margin: 3px 0 0 5px;
  text-transform: uppercase;

  ${media.large`
    font-size: 12px;
  `}
`;
