import React from 'react';
import css from 'src/theme/css';
import { graphql } from 'gatsby';

import ModuleWrapper from 'src/components/ModuleWrapper';
import RichText from 'src/components/RichText';
import Accordion from 'src/components/Accordion';
import SmartButton from 'src/components/SmartButton';
import { Flex, Box } from 'src/components/FlexBox';

export default ({ alignment, text, accordion, buttons, ...other }) => {
  return (
    <ModuleWrapper
      {...other}
      justifyContent="center"
      flexDirection="column"
      alignItems="stretch"
      css={css({
        px: 'pageMargin',
        py: 'sectionMargin',
      })}
    >
      <Flex flexDirection="column" gy={5}>
        {text && (
          <RichText
            {...text}
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            justifyContent="center"
            size="m"
          />
        )}
        <Box
          css={css({
            maxWidth: '80rem',
            width: '100%',
            flexDirection: 'column',
            alignSelf: 'center',
          })}
        >
          <Accordion {...accordion} />
        </Box>
        {buttons instanceof Array && (
          <Flex gx={5} gy={4} justifyContent="center" flexWrap="wrap">
            {buttons.map((button) => (
              <SmartButton key={button.id} {...button} />
            ))}
          </Flex>
        )}
      </Flex>
    </ModuleWrapper>
  );
};

export const query = graphql`
  fragment AccordionModuleFragment on ContentfulAccordionModule {
    id
    colorMode
    text {
      json
    }
    accordion {
      ...AccordionFragment
    }
    buttons {
      ...ButtonFragment
    }
    background {
      ...ImageGroupFragment
    }
  }
`;
