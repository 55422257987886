import React from 'react';

import { Wrapper, Label, ToggleBtn, ToggleBtnInner, Discount } from './style';

const PricingToggle = ({ isAnnual, setIsAnnual }) => (
  <Wrapper>
    <Label isActive={!isAnnual}>Monthly</Label>
    <ToggleBtn
      onClick={() => {
        setIsAnnual(!isAnnual);
      }}
    >
      <ToggleBtnInner isAnnual={isAnnual} />
    </ToggleBtn>
    <Label isActive={isAnnual}>Annual</Label>
    <Discount>(Save 13%)</Discount>
  </Wrapper>
);

export default PricingToggle;
