import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { trackPageView } from 'src/utils/analytics';

import Layout from 'src/components/Layout';
import PricingTable from 'src/components/PricingTable';
import CustomModule from 'src/components/CustomModule';
import ColumnModule from 'src/components/ColumnModule';
import AccordionModule from 'src/components/AccordionModule';

const PricingPage = ({ data }) => {
  useEffect(() => trackPageView(), []);

  return (
    <Layout isNavTransparent={true}>
      <PricingTable heroImage={data.heroImage} />
      <AccordionModule {...data.faq} />
      <ColumnModule {...data.cta} />
      <CustomModule {...data.appleAward} />
    </Layout>
  );
};

PricingPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default PricingPage;

export const pageQuery = graphql`
  query {
    heroImage: contentfulAsset(title: { eq: "pricing-hero" }) {
      fluid(maxWidth: 2880, quality: 90) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    appleAward: contentfulCustomModule(slug: { eq: "global#app-cta" }) {
      ...CustomModuleFragment
    }
    faq: contentfulAccordionModule(entryTitle: { eq: "Creative Cloud | FAQ" }) {
      ...AccordionModuleFragment
    }
    cta: contentfulColumnModule(slug: { eq: "pricing#cta" }) {
      ...ColumnModuleFragment
    }
  }
`;
