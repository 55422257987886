import React from 'react';
import styled, { css } from 'styled-components';
import themeCss from 'src/theme/css';
import { isString } from 'typical';
import Img from 'gatsby-image';
import SmartButton, * as smartButton from 'src/components/SmartButton';
import Stack, * as stack from 'src/components/Stack';
import Text from 'src/components/Text';
import Tooltip from 'src/components/Tooltip';
import OptionalWrapper from 'src/components/OptionalWrapper';
import ColorProvider from 'src/components/ColorProvider';
import ModuleWrapper from 'src/components/ModuleWrapper';
import { Box } from 'src/components/FlexBox';

const Cell = styled.td(
  ({ theme, isHighlighted, small }) => css`
    position: relative;
    padding: ${small ? '0.5rem' : '1rem'} 1rem;
    vertical-align: middle;
    text-align: center;
    background: white;

    &:not(:first-child) {
      border-left: solid 1px ${theme.color.gray100};
    }

    ${isHighlighted &&
    css`
      &::before {
        content: '';
        pointer-events: none;
        position: absolute;
        z-index: 1;
        top: 0;
        left: -40px;
        width: 40px;
        height: 100%;
        background: linear-gradient(
          to left,
          rgba(0, 0, 0, 0.05),
          rgba(0, 0, 0, 0)
        );
      }

      &::after {
        content: '';
        pointer-events: none;
        position: absolute;
        z-index: 1;
        top: 0;
        right: -40px;
        width: 40px;
        height: 100%;
        background: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.05),
          rgba(0, 0, 0, 0)
        );
      }
    `};
  `
);

const TitleCell = styled(Cell)(
  ({ small }) => css`
    text-align: start;
    padding: ${small ? '0.25rem' : '1rem'} 1rem;
    padding-left: 3rem;
  `
);

const TableBody = styled.tbody`
  padding: 3rem 0;
`;

const HeaderCell = styled.th(
  ({ theme, isHighlighted }) => css`
    position: relative;
    color: white;
    padding: 2rem 1.5rem;
    background: ${theme.color.black50};
    height: 100%;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;

    ${isHighlighted &&
    css`
      background: linear-gradient(rgb(72, 0, 186), rgb(176, 88, 161));
    `}
  `
);

const HorizontalRule = styled.hr`
  height: 1px;
  width: 80%;
  background: ${(props) => props.theme.color.black20};
  border: none;
`;

const PriceFlex = styled.div`
  display: flex;
  align-items: flex-end;

  height: 60px;
`;

const Price = styled.div`
  position: relative;

  margin-right: 5px;
  padding-left: 10px;
`;

const PriceNumber = styled.p`
  font-size: 60px;
  line-height: 1;
  letter-spacing: -2px;
`;

const PriceCurrency = styled.p`
  position: absolute;
  top: 10px;
  left: 0;

  font-size: 14px;
  line-height: 1;
  letter-spacing: -1px;
`;

const PricePer = styled.p`
  margin-bottom: 5px;

  font-size: 16px;
  line-height: 1.2;
  letter-spacing: -0.5px;
`;

const PriceCustom = styled.p`
  display: flex;
  align-items: center;

  height: 60px;

  font-size: 40px;
  line-height: 1.2;
  letter-spacing: -2px;
`;

const DesktopTable = ({
  title,
  plans,
  supportNotice,
  backgroundImage,
  isAnnual,
  ...other
}) => {
  return (
    <ModuleWrapper
      {...other}
      colorMode="dark"
      css={themeCss({
        position: 'relative',
        zIndex: 1,
        padding: '0 calc(var(--page-margin) / 2)',
        m: '0 auto',
        mb: -5,
        pt: '5rem',
      })}
      background={{ color: 'gray200' }}
      backgroundLayers={
        <Img
          {...backgroundImage}
          style={{ position: 'absolute', height: 'auto', minHeight: '100vh' }}
        />
      }
    >
      <Box css={themeCss({ mb: '5rem', flexDirection: 'column' })}>{title}</Box>
      <table css={{ tableLayout: 'fixed', width: '100%', height: '100%' }}>
        <thead css={{ height: '100%' }}>
          <tr css={{ height: '100%' }}>
            <th>{''}</th>
            {plans.map(
              (
                {
                  id,
                  title,
                  subtitle,
                  priceBilling,
                  priceDetails,
                  priceTooltip,
                  isCustom,
                  isHighlighted,
                  price,
                  pricePer,
                  url,
                  label,
                },
                index
              ) => {
                return (
                  <HeaderCell key={id} isHighlighted={isHighlighted}>
                    <Stack
                      alignment={stack.alignments.left}
                      justification={stack.justifications.spaceBetween}
                      spacing="1rem"
                      css={{ height: '100%' }}
                    >
                      <Text as="h2" variant="heading.m">
                        {title}
                      </Text>

                      <Text
                        variant="small"
                        css={{ color: '#FFF', width: '12em' }}
                      >
                        {subtitle}
                      </Text>

                      {isCustom ? (
                        <PriceCustom>{price}</PriceCustom>
                      ) : (
                        <PriceFlex>
                          <Price>
                            <PriceCurrency>$</PriceCurrency>
                            <PriceNumber>{price}</PriceNumber>
                          </Price>

                          <PricePer>{pricePer}</PricePer>
                        </PriceFlex>
                      )}

                      {isAnnual && (
                        <Text
                          css={{ color: '#FFF', marginTop: '.25rem' }}
                          variant="small"
                        >
                          {priceBilling || <>&nbsp;</>}
                        </Text>
                      )}

                      <Text css={{ color: '#FFF' }} variant="small">
                        {priceDetails}
                      </Text>

                      <SmartButton
                        css={{ display: 'block' }}
                        variant={
                          isHighlighted
                            ? smartButton.variants.secondary
                            : smartButton.variants.primary
                        }
                        action={
                          isString(url)
                            ? smartButton.actions.link
                            : smartButton.actions.signUp
                        }
                        label={label}
                        url={url}
                      />
                    </Stack>
                  </HeaderCell>
                );
              }
            )}
          </tr>
        </thead>
        <ColorProvider mode="light">
          <TableBody>
            <tr>
              <Cell css={{ borderTopLeftRadius: '1rem', height: '1rem' }} />
              {plans.map(({ id, isHighlighted }) => (
                <Cell key={id} isHighlighted={isHighlighted} />
              ))}
            </tr>
            <tr>
              <TitleCell>
                <Text variant="heading.s">Projects</Text>
              </TitleCell>
              {plans.map(({ id, projects, isCustom, isHighlighted }) =>
                isCustom ? (
                  <Cell key={id} isHighlighted={isHighlighted} rowSpan="4">
                    <Text variant="small">
                      Tailor a package for the way your organization works.
                    </Text>
                  </Cell>
                ) : (
                  <Cell key={id} isHighlighted={isHighlighted}>
                    <OptionalWrapper
                      Component={projects.tooltip && Tooltip}
                      text={projects.tooltip}
                    >
                      <Text>{projects.text}</Text>
                    </OptionalWrapper>
                  </Cell>
                )
              )}
            </tr>
            <tr>
              <TitleCell>
                <Text variant="heading.s">Active storage</Text>
              </TitleCell>
              {plans
                .filter(({ isCustom }) => !isCustom)
                .map(({ id, activeStorage, isHighlighted }) => (
                  <Cell key={id} isHighlighted={isHighlighted}>
                    <OptionalWrapper
                      Component={activeStorage.tooltip && Tooltip}
                      text={activeStorage.tooltip}
                    >
                      <Text>{activeStorage.text}</Text>
                    </OptionalWrapper>
                  </Cell>
                ))}
            </tr>
            <tr>
              <TitleCell>
                <Text variant="heading.s">Archival storage</Text>
              </TitleCell>
              {plans
                .filter(({ isCustom }) => !isCustom)
                .map(({ id, archivalStorage, isHighlighted }) => (
                  <Cell key={id} isHighlighted={isHighlighted}>
                    <OptionalWrapper
                      Component={archivalStorage.tooltip && Tooltip}
                      text={archivalStorage.tooltip}
                    >
                      <Text>{archivalStorage.text}</Text>
                    </OptionalWrapper>
                  </Cell>
                ))}
            </tr>
            <tr>
              <TitleCell>
                <Text variant="heading.s">Camera to Cloud</Text>
              </TitleCell>
              {plans
                .filter(({ isCustom }) => !isCustom)
                .map(({ id, cameraToCloud, isHighlighted }) => (
                  <Cell key={id} isHighlighted={isHighlighted}>
                    <OptionalWrapper
                      Component={cameraToCloud.tooltip && Tooltip}
                      text={cameraToCloud.tooltip}
                    >
                      <Text>{cameraToCloud.text}</Text>
                    </OptionalWrapper>
                  </Cell>
                ))}
            </tr>
            <tr aria-hidden="true">
              <Cell />
              {plans.map(({ id, isHighlighted }) => (
                <Cell key={id} isHighlighted={isHighlighted}>
                  <HorizontalRule />
                </Cell>
              ))}
            </tr>
            <tr>
              <TitleCell small css={{ verticalAlign: 'top' }}>
                <Text variant="heading.s">Included in plan</Text>
              </TitleCell>
              {plans.map(({ id, isHighlighted, hasEverythingIn, features }) => (
                <Cell
                  small
                  key={id}
                  isHighlighted={isHighlighted}
                  css={{ verticalAlign: 'top' }}
                >
                  <Stack spacing="1rem">
                    {hasEverythingIn && (
                      <Text variant="small" textAlign="center">
                        <strong>Everything in {hasEverythingIn} +</strong>
                      </Text>
                    )}
                    {features.map((feature, index) => (
                      <Tooltip
                        key={index}
                        text={feature.tooltip}
                        url={feature.url}
                      >
                        <Text variant="small" textAlign="center">
                          {feature.text}
                        </Text>
                      </Tooltip>
                    ))}
                  </Stack>
                </Cell>
              ))}
            </tr>
          </TableBody>
        </ColorProvider>
      </table>
      <ColorProvider mode="light">
        <Box
          alignItems="center"
          textAlign="center"
          justifyContent="center"
          css={themeCss({
            bg: 'white',
            borderTop: 'solid 1px',
            borderColor: 'gray100',
            borderBottomLeftRadius: 2,
            borderBottomRightRadius: 2,
            p: 5,
          })}
        >
          {supportNotice}
        </Box>
      </ColorProvider>
    </ModuleWrapper>
  );
};

export default DesktopTable;
