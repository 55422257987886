import styled, { css } from 'styled-components';
import { media } from 'src/utils/mixins';

import ChevronRight from 'src/assets/svg/ChevronRight';

export const Wrapper = styled.div`
  border-radius: 1rem;
  box-shadow: 0 12px 44px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const HeadBtn = styled.button`
  display: block;
  position: relative;
  text-align: left;
  width: 100%;
`;

export const Head = styled.div`
  background-color: ${({ theme }) => theme.color.black50};
  border-radius: 1rem;
  color: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;

  border-radius: 1rem 1rem 0 0;

  ${(props) =>
    props.isHighlighted &&
    css`
      background: linear-gradient(rgb(72, 0, 186), rgb(176, 88, 161));
    `};
`;

export const Name = styled.h2`
  ${({ theme }) => theme.text.h2};
`;

export const PlanInfo = styled.div``;

export const Price = styled.div`
  ${({ theme }) => theme.text.h3};
`;

export const Seats = styled.div`
  ${({ theme }) => theme.text.avenirMedium};

  font-size: 14px;
  letter-spacing: -0.25px;
  margin: 0 0 5px;
  text-transform: uppercase;
`;

export const Description = styled.p`
  ${({ theme }) => theme.text.p};

  margin-top: 1rem;
`;

export const AdditionalSeats = styled.p`
  ${({ theme }) => theme.text.avenirRegular};

  color: ${({ theme }) => theme.color.white50};
  font-size: 14px;
  margin: 10px 0 0;
`;

export const FeatureGroupWrapper = styled.div(
  ({ theme }) => css`
  ${theme.text.p}
  background-color: ${theme.color.white};
  border-radius: 0 0 1rem 1rem;
  padding: 2rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`
);

export const CTAWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 30px 0 0;
`;

export const BuyNowLink = styled.a`
  ${({ theme }) => theme.text.avenirMedium};

  color: ${({ theme }) => theme.color.black50};
  border-bottom: 1px dashed ${({ theme }) => theme.color.black50};
  display: block;
  font-size: 14px;
  margin: 15px auto 0;
  padding: 0 0 3px;
  text-decoration: none;
  transition: color 100ms ${({ theme }) => theme.ease.short},
    border-color 100ms ${({ theme }) => theme.ease.short};

  &:hover {
    border-color: ${({ theme }) => theme.color.black};
    color: ${({ theme }) => theme.color.black};
  }
`;

export const ExpandIcon = styled(ChevronRight)`
  fill: ${({ theme }) => theme.color.white50};
  height: 16px;
  position: absolute;
  right: 30px;
  transition: transform 100ms ${({ theme }) => theme.ease.short};
  top: 40px;
  width: 11px;

  ${(props) =>
    props.isExpanded &&
    `
    transform: rotate(90deg);
  `};

  ${media.small`
    top: 44px;
  `}
`;

export const HorizontalRule = styled.hr`
  height: 1px;
  margin: 2rem 0;
  background: ${(props) => props.theme.color.black20};
  border: none;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 2rem;
`;
