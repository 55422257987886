import React from 'react';
import css from 'src/theme/css';
import Plan from './Plan';
import { Box, Grid } from 'src/components/FlexBox';
import ModuleWrapper from 'src/components/ModuleWrapper';

const MobileTable = ({
  title,
  plans,
  supportNotice,
  backgroundImage,
  isAnnual,
  ...other
}) => {
  return (
    <ModuleWrapper
      {...other}
      colorMode="dark"
      css={css({
        position: 'relative',
        px: 'pageMargin',
        py: 'sectionMargin',
        m: '0 auto',
      })}
      background={{ smallImage: backgroundImage, largeImage: backgroundImage }}
    >
      <Box css={css({ mb: 'sectionMargin', flexDirection: 'column' })}>
        {title}
      </Box>
      <Grid
        css={css({
          justifyItems: 'center',
          gridTemplateColumns: '1fr',
          gridGap: '2rem',
        })}
      >
        {plans.map((plan) => (
          <Plan isAnnual={isAnnual} key={plan.id} {...plan} />
        ))}
      </Grid>
      <Box
        css={css({
          mt: 'sectionMargin',
          flexDirection: 'column',
          textAlign: 'center',
        })}
      >
        {supportNotice}
      </Box>
    </ModuleWrapper>
  );
};

export default MobileTable;
