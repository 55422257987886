import React, { useState, useEffect } from 'react';
import css from 'src/theme/css';
import DesktopTable from './DesktopTable';
import MobileTable from './MobileTable';
import Link from 'src/components/Link';
import Text from 'src/components/Text';
import TextStack from 'src/components/TextStack';
import SmartButton from 'src/components/SmartButton';
import ModuleWrapper from 'src/components/ModuleWrapper';
import { useBreakpoint } from 'src/components/Breakpoints';
import PricingToggle from './PricingToggle';
import theme from 'src/theme';
import { plans } from './data';

export default ({ heroImage }) => {
  const isDesktop = useBreakpoint(1);
  const [renderDesktop, setRenderDesktop] = useState(true);
  const [isAnnual, setIsAnnual] = useState(false);

  const supportNotice = (
    <Text as="p">
      Don’t see your plan? Connect with support{' '}
      <Link url="mailto:support@frame.io?subject=New%20pricing%20plans">
        here
      </Link>{' '}
      to switch.
    </Text>
  );

  const title = (
    <TextStack alignItems="center" textAlign="center">
      <Text variant="heading.xxxl" as="h1">
        Plans designed for different needs
      </Text>
      <PricingToggle isAnnual={isAnnual} setIsAnnual={setIsAnnual} />
    </TextStack>
  );

  const tableProps = {
    isAnnual,
    isHero: true,
    backgroundImage: heroImage,
    plans: plans[isAnnual ? 'annual' : 'monthly'],
    supportNotice,
    title,
  };

  useEffect(() => {
    if (isDesktop !== undefined) {
      setRenderDesktop(isDesktop);
    }
  }, [isDesktop]);
  return (
    <>
      {renderDesktop ? (
        <DesktopTable {...tableProps} />
      ) : (
        <MobileTable {...tableProps} />
      )}

      <ModuleWrapper
        background={{ color: 'sunset' }}
        css={css({
          px: 'pageMargin',
          pt: isDesktop
            ? 'calc(var(--section-margin) + 4rem)'
            : 'sectionMargin',
          pb: 'sectionMargin',
        })}
      >
        <TextStack alignItems="center" textAlign="center" size="m">
          <Text as="h2" variant="heading.xxl">
            Frame.io for Adobe Creative Cloud
          </Text>
          <Text as="p">
            Frame.io for Adobe Creative Cloud includes two members, five
            projects, 100GB of active storage, and Camera to Cloud. Subscribers
            also receive a free 30-day trial of the Team Plan to try out.
          </Text>
          <SmartButton
            action="Link"
            label="Sign in with Adobe"
            url="https://accounts.frame.io/welcome"
            variant="Knockout"
          />
        </TextStack>
      </ModuleWrapper>
    </>
  );
};
