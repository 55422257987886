import React from 'react';
import css from 'src/theme/css';
import { graphql } from 'gatsby';

import ModuleWrapper from 'src/components/ModuleWrapper';
import RichText from 'src/components/RichText';

const alignContentLookup = {
  Left: 'flex-start',
  Center: 'center',
  Right: 'flex-end',
  Document: 'center',
  Wide: 'center',
};

const textAlignLookup = {
  Left: 'start',
  Center: 'center',
  Right: 'start',
  Document: 'start',
  Wide: 'center',
};

const alignItemsLookup = {
  Left: 'stretch',
  Center: 'center',
  Right: 'stretch',
  Document: 'stretch',
  Wide: 'center',
};

const maxWidthLookup = {
  Left: '60rem',
  Center: '60rem',
  Right: '60rem',
  Document: '70rem',
  Wide: '90rem',
};

export default ({ alignment, text, splitLists, collapseMargin, ...other }) => {
  const listsStyles = {
    ul: {
      display: 'grid',
      gridTemplateColumns: splitLists ? ['1fr', '1fr 1fr'] : ['1fr'],
      columnGap: '48px',
      li: {
        textAlign: 'left',
      },
    },
  };

  return (
    <ModuleWrapper
      {...other}
      justifyContent="center"
      flexDirection="column"
      alignItems={alignContentLookup[alignment]}
    >
      <RichText
        {...text}
        flexDirection="column"
        alignItems={alignItemsLookup[alignment]}
        justifyContent="center"
        collapseMargin={collapseMargin}
        size={alignment === 'Document' ? 'none' : 'm'}
        css={css({
          ...listsStyles,
          px: 'pageMargin',
          py: 'sectionMargin',
          textAlign: textAlignLookup[alignment],
          maxWidth: maxWidthLookup[alignment],
        })}
      />
    </ModuleWrapper>
  );
};

export const query = graphql`
  fragment ColumnModuleFragment on ContentfulColumnModule {
    id
    slug
    colorMode
    alignment
    collapseMargin
    splitLists
    isViewportHeight
    hasScrim
    text {
      json
    }
    background {
      ...ImageGroupFragment
    }
  }
`;
