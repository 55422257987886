import React from 'react';
import { isString } from 'typical';
import css from 'src/theme/css';

import Stack from 'src/components/Stack';
import Text from 'src/components/Text';
import SmartButton, * as smartButton from 'src/components/SmartButton';
import Cluster from 'src/components/Cluster';
import ColorProvider from 'src/components/ColorProvider';

import * as S from './style';

const Row = ({ name, value }) => (
  <>
    <Text variant="small">{name}</Text>
    <Text>{value}</Text>
  </>
);

export default ({
  activeStorage,
  archivalStorage,
  cameraToCloud,
  features,
  hasEverythingIn,
  isAnnual,
  isCustom,
  isHighlighted,
  price,
  priceBilling,
  priceDetails,
  pricePer,
  projects,
  subtitle,
  title,
  url,
}) => {
  return (
    <S.Wrapper css={{ maxWidth: '40rem' }}>
      <S.Head isHighlighted={isHighlighted}>
        <Text css={css({ mb: 3 })} variant="heading.xl">
          {title}
        </Text>

        <Text css={css({ mb: 4 })}>{subtitle}</Text>

        <S.PlanInfo>
          <Cluster alignItems="center" spacing="1rem">
            <Text variant="heading.xxl">
              {!isCustom && '$'}
              {price}
            </Text>

            <Text css={{ lineHeight: '1.2' }} variant="small">
              {pricePer}
            </Text>
          </Cluster>

          {isAnnual && (
            <Text css={css({ mt: 3, mb: 4 })} variant="small">
              {priceBilling}
            </Text>
          )}

          <Text css={css({ mt: isAnnual ? 0 : 3 })} variant="small">
            {priceDetails}
          </Text>
        </S.PlanInfo>
      </S.Head>

      <ColorProvider mode="light">
        <S.FeatureGroupWrapper>
          {isCustom ? (
            <Text
              textAlign="center"
              css={{ maxWidth: '20em', alignSelf: 'center' }}
            >
              Tailor a package for the way your organization works.
            </Text>
          ) : (
            <S.Grid>
              {projects && <Row name="Projects" value={projects.text} />}
              {activeStorage && (
                <Row name="Active storage" value={activeStorage.text} />
              )}
              {archivalStorage && (
                <Row name="Archival storage" value={archivalStorage.text} />
              )}
              {cameraToCloud && (
                <Row name="Camera to Cloud" value={cameraToCloud.text} />
              )}
            </S.Grid>
          )}
          <S.HorizontalRule />
          <Text
            textAlign="center"
            variant="heading.m"
            css={{ marginBottom: '1rem' }}
          >
            Included in plan
          </Text>
          <Stack css={{ marginBottom: '2rem' }}>
            {hasEverythingIn && (
              <Text textAlign="center" variant="small">
                <b>Everything in {hasEverythingIn} +</b>
              </Text>
            )}
            {features.map((feature, index) => (
              <Text textAlign="center" variant="small" key={index}>
                {feature.text}
              </Text>
            ))}
          </Stack>
          <SmartButton
            css={{ marginTop: 'auto', alignSelf: 'center' }}
            variant={smartButton.variants.primary}
            action={
              isString(url)
                ? smartButton.actions.link
                : smartButton.actions.signUp
            }
            label={isCustom ? 'Chat with sales' : 'Try it free'}
            url={url}
          />
        </S.FeatureGroupWrapper>
      </ColorProvider>
    </S.Wrapper>
  );
};
